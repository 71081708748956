import { get } from './http'

export const packageList = () => {
  const url = '/package'
  return get(url)
}

export const packageInfo = (params) => {
  const url = '/package/detail'
  return get(url, params)
}
